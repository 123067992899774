<template>
  <div class="">
    <!-- Sync Loading UI -->
    <trac-dual-sync
      v-if="isACleanSlate"
      :status="'syncing'"
      :progress="progress"
    ></trac-dual-sync>
    <!-- Normal Loading UI -->
    <main v-else class="md:pl-12 pr-4 md:pr-0">
      <div v-if="categories && categories.length === 0" class="h-128">
        <div
          class="flex flex-col text-center items-center justify-center h-full"
        >
          <span class="font-semibold text-xl text-gray-500"
            >No categories are available.<br />Create a new category by clicking
          </span>
          <div class="mx-auto mt-8">
            <trac-button
            data-test="new-category"
              v-if="
                permissionsModules[4].parent.permissionsForUser.createCategory
              "
              class="uppercase text-xs"
              @button-clicked="gotoAddCategory"
              >New Category
            </trac-button>
            <p v-else class="text-sm">No permissions to view this.</p>
          </div>
        </div>
      </div>
      <div class="" v-else>
        <div class="flex flex-row items-end justify-between mb-8">
          <div class="flex-column mt-12">
            <!-- <h2>Products</h2> -->
            <h3 class="font-bold mt-4 text-lg">Categories ({{ categories.length }})</h3>
          </div>
          <div
            v-if="
              categories &&
              permissionsModules[4].parent.permissionsForUser.createCategory && isOnline
            "
            class="flex-column"
          >
            <trac-button
              data-test="new-category"
              class="uppercase text-xs"
              @button-clicked="gotoAddCategory"
              >New Category</trac-button
            >
          </div>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
          <div
            v-for="(category, i) in categories"
            :key="i"
            @click="gotoCategoryDetail(category)"
            class="
              small-shadow
              bg-white
              rounded-lg
              card-gradient
              hover:text-white
              cursor-pointer
            "
          >
            <div class="flex flex-row items-center">
              <div
                class="
                  rounded-full
                  flex flex-column
                  bg-accentLight
                  mx-5
                  my-8
                  py-2
                  items-center
                "
              >
                <img class="mx-auto" src="../../assets/svg/Vector.svg" />
              </div>
              <ul class="flex-column mr-5 hover:text-white">
                <li class="flex-column font-semibold text-sm capitalize">
                  {{ category.category.name }}
                </li>
                <li class="text-xs">{{ `${category.category.count} Product(s)` }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import AdvancedSearch from "../../offline-module/advancedSearch";
import { cleanSlate } from "../../offline-module/offline.store";
import {
  DELETE_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
  GET_USER_BUSINESS_ID
} from "../../browser-db-config/localStorage";
import { permissionsModules } from "./../settings/permission";

import { eventBus } from "./../../main";
import categories from '../../store/modules/categories';

export default {
  name: "ProductCategories",
  data() {
    return {
      isLoading: false,
      categories: [],
      progress: {
        name: "",
        recordsDone: 0,
        total: 0,
      },
      permissionsModules,
      adv: new AdvancedSearch({
        BusinessId: GET_USER_BUSINESS_ID(),
        offlineManager: this.$GlobalOfflineManager,
      }),
    };
  },
  async created() {
    await this.NetworkCheck();
    this.dualSyncStatus = "syncing";
    await this.loadOfflineRecords()
    // this.isLoading = true;
    // DELETE_LOCAL_DB_DATA("category-detail");
    // await this.fetchAllCategories();
    // this.isLoading = false;
  },
  watch: {
    SWData(newValue, oldValue) {
      console.log("Categories -> SWData, ", newValue); // For debugging/checking this fires in console.log
      if (this.isADownloadOnlySyncEvent(newValue, 'categories')) {
        // this.dualSyncStatus = false;
        console.log('category download event detected');
        cleanSlate.moduleList["categories"] = false;
        this.loadOfflineRecords();
      }
      if (this.isAProgressSyncEvent(newValue, 'categories')){
        this.progress = newValue.data;
      }
    }
  },
  computed: {
    sortedCategories() {
      return this.categories.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    isACleanSlate() {
      return cleanSlate.moduleList["categories"] === true;
    }
  },
  methods: {
    async loadOfflineRecords() {
      // const results = await this.adv.paginateRecords({
      //   tableName: "categories",
      //   pageIndex: 0,
      //   loopThrough: true,
      //   resultsPerSearch: 30,
      // })
      // this.categories = results
      this.categories = await this.$GlobalOfflineManager.getAll('categories')
      this.isLoading = false;
    },
    gotoCategoryDetail(category) {
      console.log('proceeding to save category details: ', category)
      SAVE_LOCAL_DB_DATA("category-detail", category);
      this.$router.push({ name: "CategoriesList" });
    },
    gotoAddCategory() {
      DELETE_LOCAL_DB_DATA("category-detail")
      this.$router.push({ name: "AddCategories" });
    },
  },
};
</script>

<style scoped>
.card-gradient:hover {
  background: linear-gradient(180deg, #0143aa 0%, #003283 100%);
}
</style>
